import styled from 'styled-components'
import theme from 'common/style/styleSetup'

export const Wrapper = styled.div`
  width: 100%;
`

export const Label = styled.div`
  background: #2c3a5c;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 5px;
  height: 40px;
  margin: 7px 5px;
  font-size: 12px;
  line-height: 36px;
  padding: 2px 10px 0;
  position: relative;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 4px;
  color: #ffa604;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0 2px;
`

export const Item = styled.div`
  background: #1b2333;
  height: 35px;
  padding: 7px 10px;
  cursor: pointer;
  line-height: 20px;
  font-size: 12px;
  transition: 200ms all;
  border-top: solid 1px #273248;
  border-bottom: solid 1px #0f131d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
    background: #273147;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 8px;
    .icon {
      img {
        width: 20px;
      }
    }
    .label {
      font-size: 12px;
      color: ${theme.color[0]};
      /* font-weight: bold; */
    }
  }
  .right {
    width: 40px;
    height: 22px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: ${theme.color[8]};
    font-weight: bold;
    text-align: center;
    background: #00000040;
    color: #68e0ff;
    padding: 2px 2px 0;
    border: solid 1px #00000050;
  }
`
