import styled from 'styled-components'
import theme from 'common/style/styleSetup'

export const Wrapper = styled.div`
  width: 100%;
  background: transparent;
`

export const Chrome = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 6px 0 0;
  .img {
    width: 40px;
  }
  .text {
    width: calc(100% - 40px);
    font-size: 12px;
    color: #fff;
    line-height: 20px;
    span {
      margin-left: 6px;
      color: #ffd700;
    }
  }
`

export const ButtonBox = styled.div`
  width: 100%;
  height: auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  .item {
    cursor: pointer;
  }
`
