import styled from 'styled-components'

// interface ItemProps {
//   bg?: any;
// }

export const Container = styled.div`
  height: 831px;
  padding: 87px 200px 48px;
  background-color: #5c7161;
  .slick-slider {
    max-width: 1294px;
    margin: 0 auto;
    height: 650px;

    @media (max-width: 768px) {
      height: auto;
    }

    .slick-list {
      height: 650px;
      border-radius: 5px;
      @media (max-width: 768px) {
        height: auto;
      }
    }
  }
  .slick-dots {
    position: relative;
    top: 36px;

    .slick-active {
      width: 24px;
      height: 11px;
      margin: 0 6.5px;
      border-radius: 5px;
      background-color: #f0f1e4;
    }

    li {
      width: 11px;
      height: 11px;
      margin: 0 12px 0 13px;
      background-color: #818e7f;
      border-radius: 50%;

      button:before {
        display: none;
      }
    }

    @media (max-width: 768px) {
      top: 40px;
    }

    @media (max-width: 425px) {
      top: 28px;
    }
  }

  @media (max-width: 1440px) {
    padding: 30px 100px 80px 100px;
    height: auto;
  }

  @media (max-width: 1024px) {
    padding: 38px 16px 87px;
    height: auto;
  }

  @media (max-width: 768px) {
    padding: 38px 16px 87px;
    height: auto;
  }

  @media (max-width: 425px) {
    padding: 38px 16px 87px;
    height: auto;
  }
`

export const SlideItem = styled.div`
  width: 100%;
  min-height: 637px;
  background-color: white;
  border-radius: 8px;
  display: flex !important;
  flex-direction: row;
  align-items: center;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .item-left {
    flex: 1;
    padding: 38px 47.5px 59px 55px;
    width: 50%;
    height: 650px;
    position: relative;
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
    transform-origin: center top;
    perspective-origin: left top;

    &__title {
      font-size: 27px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: -1.89px;
      color: #5c7161;
      font-family: 'Montserrat-Medium';
      text-overflow: clip;
      overflow: hidden;
      word-spacing: normal;
      word-break: break-all;

      &.author {
        letter-spacing: -0.68px;
      }
    }

    &__art {
      width: 100%;
      /* height: auto; */
      height: 400px;
      margin: 16px 0 15px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @media (max-width: 1024px) {
        width: auto;
      }
      @media (max-width: 767px) {
        width: auto;
        height: 300px;
        max-width: 100%;
      }

      @media (max-width: 425px) {
        width: 100%;
        height: 250px;
      }
    }

    &__artist {
      font-family: 'Montserrat-Regular';
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: -0.43px;
      text-align: center;
      color: #5c7161;
    }

    &__size {
      font-family: 'Montserrat-Light';
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: -0.43px;
      text-align: center;
      color: #5c7161;
    }

    &.showInfo {
      -webkit-transform: translateY(-100%);
      -moz-transform: translateY(-100%);
      -o-transform: translateY(-100%);
      transform: translateY(-100%);
      transform-origin: center top;
      perspective-origin: center top;
    }

    .back {
      position: absolute;
      left: 16px;
      color: #607664;
      font-size: 33px;
      cursor: pointer;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .open-information {
      color: #607664;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      border: 1px solid #607664;
      width: 120px;
      height: 45px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;

      @media screen and (min-width: 769px) {
        display: none;
      }
    }

    @media (max-width: 1024px) {
      width: 100%;
      padding: 30px;
    }

    @media (max-width: 768px) {
      width: 100%;
      /* height: 50% !important; */
      /* padding: 30px 30px 100px 30px; */
      height: 100%;
      padding: 30px 30px 35.5px 30px;

      // New Custom
      position: absolute;
      background-color: #ffffff;
      z-index: 2;
      transition: all 0.5s;

      /* &:focus {
        -webkit-transform: skewX(90deg);
        -moz-transform: skewX(90deg);
        -o-transform: skewX(90deg);
        transform: skewX(90deg);
        transform-origin: center top;
        perspective-origin: center top;
      } */
    }

    @media (max-width: 540px) {
      padding: 26px 0px 35.5px 0px;
      &__title {
        padding: 0px 15px;
      }
    }

    @media (max-width: 425px) {
      width: 100%;
      /* padding: 26px 0px 35.5px 0px; */
      padding: 26px 0px 0px 0px;
    }
  }

  .item-line {
    width: 1px;
    height: 514px;
    background-color: #5d7160;

    @media (max-width: 768px) {
      width: 100%;
      /* height: 1px; */
      height: 0px;
    }
  }

  .item-right {
    flex: 1;
    padding: 64px 65px 58px 59.5px;
    position: relative;
    width: calc(50% -1px);

    &__title {
      font-family: 'Montserrat-Bold';
      font-size: 23px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: -1.61px;
      text-align: left;
      color: #5c7161;
    }

    &__tab {
      width: 100%;
      height: 44px;
      display: flex;
      border: solid 1px #607664;
      margin: 35px 0px 27px 0px;

      &-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat-Medium';
        font-size: 21px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -1.47px;
        text-align: center;
        color: #5d7160;

        /* &:nth-child(2) {
          border-left: solid 1px #607664;
          border-right: solid 1px #607664;
          overflow: hidden scroll;
        } */

        &.active {
          background-color: #607664;
          font-family: 'Montserrat-SemiBold';
          color: #fff;
        }

        @media (max-width: 1024px) {
          font-size: 18px;
        }

        @media (max-width: 425px) {
          font-size: 13px;
        }
      }

      @media (max-width: 425px) {
        width: 100%;
        margin: 17px 0px 31px 0px;
      }
    }

    &__chart {
      width: 167px;
      height: 167px;
    }

    &__price {
      width: 80%;
      height: auto;
      margin: 32px 0px;
      border: solid 2px #5d7160;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #5c7161;

      &-left,
      &-right {
        text-align: center;
        align-self: center;
        justify-self: center;
        flex: 1;
        font-family: 'Montserrat-Medium';
        font-size: 21px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: -1.47px;
        display: flex;
        flex-direction: column;

        @media (max-width: 1024px) {
          font-size: 18px;
        }

        @media (max-width: 425px) {
          font-size: 16px;
        }
      }

      &-line {
        width: 1px;
        height: 77px;
        background-color: #5d7160;
      }
      @media (max-width: 1250px) {
        width: 90%;
      }

      @media (max-width: 1024px) {
        width: 100%;
      }

      @media (max-width: 425px) {
        width: 100%;
        margin: 31px 0px 17px 0px;
      }
    }

    .bold {
      font-family: 'Montserrat-SemiBold';
      font-weight: 600;
    }

    &__btn {
      width: 213px;
      height: 44px;
      background-color: #607664;
      color: #fff;
      border: none;
      outline: none;
      font-family: 'Montserrat-Medium';
      &:hover {
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
      }
    }

    .next {
      position: absolute;
      right: 16px;
      color: #607664;
      font-size: 33px;
      cursor: pointer;

      @media (max-width: 768px) {
        display: none;
      }
    }

    @media (max-width: 1024px) {
      width: 50%;
      padding: 30px;
    }

    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
      /* padding: 35.5px 0px 69px 0px; */

      // New Custom
      padding: 35.5px 15px 69px 15px;
      position: absolute;
      z-index: 1;
    }

    @media (max-width: 425px) {
      width: 100%;
      /* padding: 35.5px 0px 49px 0px; */

      // New Custom
      padding: 35.5px 15px 49px 15px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 0px 19px 0px 19px;

    // New Custom
    position: relative;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    height: auto;
    padding: 0px 19px 0px 19px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .percentage {
    font-family: 'Montserrat-SemiBold';
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: -0.75px;
    text-align: center;
    color: #5d7160;
  }

  .chart-line {
    width: 41px;
    height: 2px;
    margin: 9px 18px 7px 17px;
    background-color: #5d7160;
  }

  .chart-content {
    font-family: 'Montserrat-Medium';
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: -1.19px;
    text-align: center;
    color: #5d7160;
  }
`
