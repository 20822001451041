import types from '../actions/types'

const defaultState = {
  querySearch: undefined,
  typeSearch: undefined,
  startPrice: undefined,
  endPrice: undefined
}

export default (state = defaultState, action: any) => {
  switch (action.type) {
    case types.FILTER_ON_LEFT_MENU:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
