import styled from 'styled-components'
import theme from 'common/style/styleSetup'

export const Wrapper = styled.div`
  margin: 0 0 4px;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  background: #273147;
  box-shadow: rgb(82, 82, 93) 1px 1px 4px -2px inset;
`

export const Label = styled.div`
  /* background: #2c3a5c;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 5px;
  height: 40px;
  margin: 7px 5px;
  font-size: 12px;
  line-height: 36px;
  padding: 2px 10px 0;
  position: relative;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 4px;
  color: #ffa604; */

  background: #1b2638;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 10px;

  font-weight: bold;
  font-size: 13px;
  color: #fff;
  span {
    color: #00c4ff;
    font-weight: normal;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  /* padding: 0 2px; */
`

export const Item = styled.div`
  width: calc(33.33% - 2px);
  margin: 1px 1px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;

  border-radius: 2px;
  background-color: #31485a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
  img {
    width: 100%;
  }
`
