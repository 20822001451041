import styled from 'styled-components'

export const Wrapper = styled.button`
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 0;
  margin-bottom: 0;
  margin-right: 0;
  border: 1px solid #0b2824;
  background: linear-gradient(
    to bottom,
    #467575 0%,
    #225249 36%,
    #0b2824 65%,
    #22443b 100%,
    #46756a 100%,
    #467559 100%
  );

  &:hover {
    background: linear-gradient(
      to bottom,
      #614675 0%,
      #392252 36%,
      #190b28 65%,
      #352244 100%,
      #584675 100%,
      #584675 100%
    );
  }
`
