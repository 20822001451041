import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  background: #161e2d;
  border: solid 1px rgba(255, 255, 255, 0.04);
  padding: 0px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
`

export const Header = styled.div`
  width: 100%;
  padding: 5px;
  p {
    font-family: 'SCDream4';
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    border-bottom: 1px solid #32363a;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    line-height: 25px;
    margin: 0;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .clock {
      color: #02a6a7;
      font-size: 16px;
      width: 50%;
      text-align: center;
    }

    .btn {
      width: fit-content;
    }
  }
`

export const Betting = styled.div`
  width: 100%;
  padding: 5px;
`

export const Table = styled.table`
  margin: 0;
  border-collapse: inherit;
  width: 100%;
  border-spacing: 0;
  word-break: break-all;
`

export const Tbody = styled.tbody`
  width: 100%;

  tr {
    th {
      margin: 0px;
      padding: 0px;
      font-size: 12px;
      color: #fff;
      line-height: 100%;
      font-weight: 400;
      box-sizing: border-box;
    }

    td {
      width: 100%;
      height: 40px;
      line-height: 35px;

      p {
        margin: 0px;
      }

      .num {
        color: #02a6a7;
        font-size: 15px;
        text-align: center;
      }

      .bg_p,
      .betmoney {
        text-align: right;
        width: 100%;
        height: 35px;
        line-height: 35px;
        border: 1px solid #444444;
        background: #171717;
        font-size: 14px;
        vertical-align: middle;
        padding: 0 20px;
        color: #fff;
        transition: 0.5s;

        &.red {
          color: #ff0000;
        }
      }
    }
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 5px;
`

export const ButtonMoneyWrappper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: auto;
  gap: 5px;
  padding-bottom: 5px;
`

export const ButtonResetWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 5px;
`

export const ButtonSubmitWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`

export const ListBetting = styled.div`
  width: 100%;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  .inner {
    width: 100%;

    .top {
      display: flex;
      flex-direction: column;
      width: 100%;
      p {
        margin: 0;
        white-space: nowrap;
        font-size: 12px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .btn {
        cursor: pointer;
        height: 22px;
        background: #c82323;
        border-radius: 1px;
        padding: 2px 10px 0;
        font-weight: 400;
      }
    }
  }
`
