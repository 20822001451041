import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// import { ReactComponent as ClosePopup } from "assets/icons/closePopup.svg";

import theme from 'common/style/themes/default'
import types from 'store/actions/types'

// import { UrlInternal } from "common/constants/endpoints";

import * as S from './style'
interface ModalProps {
  className?: string
  widthPopup?: number
}

const PopupCheck = ({ className, widthPopup }: ModalProps) => {
  // const history: any = window?.location;
  const history: any = useHistory()
  const dispatch = useDispatch()
  const popupStates = useSelector((state: any) => state.popup)
  // console.log(popupStates);
  /** Close */
  const closePopup = () => {
    dispatch({
      type: types.POPUP_ACTION_CLOSE,
      payload: { fromURL: history?.pathname ?? '' }
    })
    if (popupStates?.closeLinkToPage) {
      history?.push({
        pathname: popupStates?.closeLinkToPage,
        state: { fromURL: history.location.pathname }
      })
    }
  }

  /** onAction */
  const onAction = () => {
    // if (popupStates?.actionText) {
    //   if (popupStates?.linkToPage) {
    //     history?.push({
    //       pathname: popupStates?.linkToPage,
    //       state: { fromURL: history.location.pathname },
    //     });
    //   }
    //   closePopup();
    // }
    if (popupStates?.action) {
      popupStates?.action()
    }
    closePopup()
  }

  return (
    <S.Wrapper
      className={
        popupStates?.isCustomNotice ? 'custom-modal-notice' : className
      }
      open={popupStates?.isOpen}
      onCancel={() => closePopup()}
      width={400}
    >
      <S.Body>
        <div
          className={
            popupStates?.isCustomNotice
              ? 'custom-modal-notice content'
              : 'content'
          }
          dangerouslySetInnerHTML={{ __html: popupStates?.msg }}
        />
      </S.Body>
      {popupStates?.typePopup === 'CONFIRM' ? (
        <S.Footer>
          <div className='button ok' onClick={closePopup}>
            OK
          </div>
        </S.Footer>
      ) : (
        <S.Footer>
          <div className='button cancel' onClick={closePopup}>
            Cancel
          </div>
          <div className='button ok' onClick={onAction}>
            OK
          </div>
        </S.Footer>
      )}
      {/* {(popupStates?.typePopup === "CONFIRM" ||
        popupStates?.typePopup === "ALL") && (
        <S.Footer>
          {popupStates?.typePopup === "CONFIRM" ? (
            <S.ActionButton
              className={popupStates?.isCustomNotice ? "custom-notice" : ""}
              onClick={onAction}
            >
              {popupStates?.actionText ? popupStates?.actionText : "OK"}
            </S.ActionButton>
          ) : (
            <S.ButtonBox>
              <S.OKButton
                className={
                  popupStates?.isNotAccountMoney ? "not-account-money" : ""
                }
                onClick={onAction}
                // theme={theme.button.primary}
              >
                {popupStates?.actionText ? popupStates?.actionText : "Action"}
              </S.OKButton>
              <S.CancelButton
                className={
                  popupStates?.isNotAccountMoney ? "not-account-money" : ""
                }
                onClick={closePopup}
                // theme={theme.button.primary_outline}
              >
                {popupStates?.cancelText ? popupStates?.cancelText : "Cancel"}
              </S.CancelButton>
            </S.ButtonBox>
          )}
        </S.Footer>
      )} */}
    </S.Wrapper>
  )
}

export default PopupCheck
