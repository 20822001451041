import { mainAxios } from 'libs/axios'

interface DataGetDetail {
  type: string
  gmoney: number
  betMoney: number
  bettingcnt: number
  bettingdiv: number
  bettingwin: number
  pick_gubun: string
  select_pick: number
  game_no: number
  pick: string
}

interface DataGetHistory {
  type: string
  limit: any
  page: number | string
}

export const miniAllApi = () => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/mini`,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

export const getMiniGameDetail = (type: string) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/mini/detail/${type}`,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

export const bettingGame = (payload: DataGetDetail) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/mini/input`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

export const getHistoryBetting = (params: DataGetHistory) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/mini/history`,
    params: params,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
