import React from 'react'
import types from 'store/actions/types'

interface DataValueProps {
  dataBetting: {
    idx: string | number
    pick: string
    ratio: string
    game_type: string
    div_sel: string | undefined
    nameElement: React.ReactElement
    type: string
    childrenId: number
    isCanBet: boolean
  }[]
}

interface DataActionProps {
  payload: any
  type: string
}

const defaultValue: DataValueProps = {
  dataBetting: []
}

export default (state = defaultValue, action: DataActionProps) => {
  switch (action.type) {
    case types.ADD_DATA_BETTING_SPORTS:
      return { ...state, dataBetting: [...state.dataBetting, action.payload] }
    case types.REMOVE_DATA_BETTING_SPORTS:
      const tempData = [...state.dataBetting]
      const newData = tempData.filter((item) => item.idx !== action.payload)
      return { ...state, dataBetting: [...newData] }
    case types.RESET_DATA_BETTING_SPORTS:
      return { ...state, dataBetting: [] }
    default:
      return state
  }
}
