const types = {
  LOGIN: 'LOGIN',
  SIDEBAR_IS_OPEN: 'SIDEBAR_IS_OPEN',
  GLOBAL_STATES: 'GLOBAL_STATES',

  //order
  ADD_STORE: 'ADD_STORE',
  ADD_FOOD: 'ADD_FOOD',
  ADD_ITEM: 'ADD_ITEM',
  ADD_DISCOUNT: 'ADD_DISCOUNT',
  REMOVE_DISCOUNT: 'REMOVE_DISCOUNT',
  INFO_ORDER: 'INFO_ORDER',

  OPEN_POPUP: 'OPEN_POPUP',
  CLOSE_POPUP: 'CLOSE_POPUP',

  ThEME_SWITCHER: 'ThEME_SWITCHER',

  USER_INFO: 'USER_INFO',
  REMOVE_USER_INFO: 'REMOVE_USER_INFO',

  BANK_INFO: 'BANK_INFO',

  // SearchBar
  SEARCH_BAR: 'SEARCH_BAR',

  // Filter on Left menu (pages)
  FILTER_ON_LEFT_MENU: 'FILTER_ON_LEFT_MENU',

  // Art Order
  ART_ORDER: 'ART_ORDER',

  //Select Bar
  SELECT_BAR: 'SELECT_BAR',

  //Data User
  GET_DATA_USER: 'GET_DATA_USER',
  DELETE_DATA_USER: 'DELETE_DATA_USER',

  //Minigame Detail
  GET_INFO_BET_MINIGAME: 'GET_INFO_BET_MINIGAME',
  GET_PICK_VALUE: 'GET_PICK_VALUE',

  //Popup Action
  POPUP_ACTION_OPEN: 'POPUP_ACTION_OPEN',
  POPUP_ACTION_CLOSE: 'POPUP_ACTION_CLOSE',

  //Waitting
  WAITTING: 'WAITTING',
  STOP_WAITTING: 'STOP_WAITTING',

  // casino list
  CASINO_LIST: 'CASINO_LIST',

  // slot game list
  SLOT_GAME_LIST: 'SLOT_GAME_LIST',

  //sport game
  ADD_DATA_BETTING_SPORTS: 'ADD_DATA_BETTING_SPORTS',
  REMOVE_DATA_BETTING_SPORTS: 'REMOVE_DATA_BETTING_SPORTS',
  RESET_DATA_BETTING_SPORTS: 'RESET_DATA_BETTING_SPORTS',
  SPORTS_GAME_LIST: 'SPORTS_GAME_LIST',

  //message
  GET_MESSAGE_CONF: 'GET_MESSAGE_CONF'
}

export default types
