import React from 'react' //lazy

import { Layout } from 'antd'
// import { ArrowUpOutlined } from "@ant-design/icons";

// import { UrlInternal } from "common/constants/endpoints";

import * as S from './style'

const { Footer } = Layout

interface Props {
  onClick?: any
  className?: any
}

const FooterPage = (props: Props) => {
  const { onClick, className } = props

  return (
    <S.Footers>
      COPYRIGHT ©<span>샘플1.</span>
      ALL RIGHTS RESERVED.
    </S.Footers>
  )
}

export default FooterPage
