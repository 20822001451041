const theme = {
  color: {
    0: '#fff',
    1: '#0c0f19',
    2: '#191f28',
    3: '#3f485f',
    4: '#ffffff75',
    5: '#022034',
    6: '#9487F1',
    7: '#b2c0e4',
    8: '#c4c4c3',
    40: '#7466E3',
    50: '#4838D1',
    60: '#3528B3',
    70: '#261C96',
    80: '#191179',
    90: '#100A64',
    99: '#000000',
    100: '#090638'
  },
  font: {
    1: 'SCDream1',
    2: 'SCDream2',
    3: 'SCDream3',
    4: 'SCDream4',
    5: 'SCDream5',
    6: 'SCDream6',
    7: 'SCDream7',
    8: 'SCDream8',
    9: 'SCDream9'
  },
  bg: {
    1: '#119bbf',
    2: '#1d2333',
    3: '#7750f8',
    4: '#3f485f',
    5: '#3ac2e7',
    6: '#022034',
    7: '#0E2857',
    8: '#171717',
    9: '#213145',
    10: '#3c5c81',
    11: '#4f2785',
    12: '#7d48c3',
    13: '#268ca7',
    14: '#7d48c3',
    15: '#0a1b32',
    16: '#16253a',
    17: '#268aa5',
    18: '#161e2e',
    19: '#31485a',
    20: '#1b2638',
    21: '#354d76',
    99: '#000000'
  },
  weight: {
    1: 100,
    2: 200,
    3: 300,
    4: 400,
    5: 500,
    6: 600,
    7: 700,
    8: 800,
    9: 900
  }
}

export default theme
