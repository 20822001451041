import { combineReducers } from 'redux'
import popupReducer from './popupReducer'
import userReducer from './userReducer'
import searchReducer from './searchReducer'
import filterLeftMenuReducer from './filterLeftMenuReducer'
import orderArtReducer from './orderArtReducer'
import selectReducer from './selectReducer'
import getUserReducer from './getUserReducer'
import getListCasino from './getListCasino'
import getListSlotGame from './getListSlotGame'
import waittingReducer from './waittingReducer'
import infoMiniGameReducer from './miniGameReducer'
import pickValueReducer from './pickValueReducer'
import historyDetailBettingReducer from './historyDetailBettingReducer'
import bettingSportsReducer from './bettingSportsReducer'
import sportsGameList from './getListSportsReducer'

export default combineReducers({
  popup: popupReducer,
  user: getUserReducer,
  searchBar: searchReducer,
  filterLeftMenu: filterLeftMenuReducer,
  artOrder: orderArtReducer,
  selectBar: selectReducer,
  waitting: waittingReducer,
  casinoList: getListCasino,
  slotGameList: getListSlotGame,
  infoMinigame: infoMiniGameReducer,
  pickValue: pickValueReducer,
  historyBettingDetail: historyDetailBettingReducer,
  bettingSports: bettingSportsReducer,
  sportsGameList: sportsGameList
})
