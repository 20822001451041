import styled from 'styled-components'

export const Wrapper = styled.button`
  width: calc(100% / 3 - 4px);
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 0;
  margin-bottom: 0;
  margin-right: 0;
  border: 1px solid #190b28;
  background: linear-gradient(
    to bottom,
    #466075 0%,
    #223d52 36%,
    #0b1b28 65%,
    #223544 100%,
    #466075 100%,
    #466075 100%
  );

  &:hover {
    background: linear-gradient(
      to bottom,
      #614675 0%,
      #392252 36%,
      #190b28 65%,
      #352244 100%,
      #584675 100%,
      #584675 100%
    );
  }
`
