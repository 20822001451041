import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
    > div {
      width: 200px !important;
      height: 200px !important;
    }
  }
`
